/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS621P
 * 화면 설명: 유의/대체계약 지점장결재 팝업
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="결재" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '결재' : '결재'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt15 mb20">
              <div class="sub-text">
                <div class="info-li">
                  <span class="fs18rem fwb mr6">{{rsltItem.zaClctCnsltNoNm}}</span>
                  <span class="fs18rem fwb mr6">({{rsltItem.zaClctCnsltNo}})</span>
                </div>
                <div class="info-li">
                  <span class="mr6 fs16rem">{{rsltItem.zaFofOrgNm}}</span>
                </div>
                <div class="info-li">
                  <span><span class="mr6 fs14rem crTy-bk7">차월</span><span class="fs16rem">{{rsltItem.zaLpmfc}}</span></span><em>|</em>
                  <span><span class="mr6 fs14rem crTy-bk7">직책</span><span class="fs16rem">{{rsltItem.zaDutyNm}}</span></span><em>|</em>
                  <span><span class="mr6 fs14rem crTy-bk7">자격</span><span class="fs16rem">{{rsltItem.zaQulfNm}}</span></span>
                </div>
              </div>
              <div class="gray-bar full48 mt20" />
            </ur-box-container>
          </template>
          <!-- collapsing end -->

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container class="con-area mt15 mb20">
              <div class="info-li fexTy3 full">
                <div class="fs16rem">월말 책임액</div>
                <div class="fs16rem fwb">{{$bizUtil.numberWithCommas(rsltItem.zaEomRspnAmt)}}</div>
              </div>
              <div class="info-li mt20">
                <div class="fs16rem">당월 업적</div>
              </div>
              <!-- table -->
              <div class="table-area mt20">
                <table class="table col-type">
                  <colgroup>
                    <col width="50%" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th class="txt-left bdRN">건수</th>
                      <td class="txt-right">
                      <strong class="num-after">{{$bizUtil.numberWithCommas(rsltItem.zaExcpAplcNcontCnt)}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">환산</th>
                      <td class="txt-right">
                      <strong class="num-after">{{$bizUtil.numberWithCommas(rsltItem.zaCnvlPrfmAmt)}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">현 설계건(환산)</th>
                      <td class="txt-right">
                        <strong class="num-after">{{$bizUtil.numberWithCommas(rsltItem.zaContCnvlPrfmAmt)}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">설계건 합산(환산)</th>
                      <td class="txt-right">
                      <strong class="num-after">{{$bizUtil.numberWithCommas(rsltItem.zaSumCnvlPrfmAmt)}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">기준일자</th>
                      <td class="txt-right">
                        <strong class="bdRN">{{rsltItem.zaStndYmd}}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- //table -->
              <!-- table -->
              <div class="table-area mt20">
                <table class="table col-type">
                  <colgroup>
                    <col width="35%" />
                    <col width="35%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th rowspan="4">1.당월본인<br/>계약현황</th>
                      <th class="txt-left bdRN">건수</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaSelfNcontCnt)}}</td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">환산월초</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaSelfCnvlPrfmAmt)}}</td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">월납월초</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaSelgMpdbPrm)}}</td>
                    </tr>
                     <tr>
                      <th class="txt-left bdRN">보장월초</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaSelfCovrBomthPrm)}}</td>
                    </tr>
                    <tr>
                      <th rowspan="2">2. 보장합산<br/>유지율</th>
                      <th class="txt-left bdRN">2~13합산</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaCovrBomthAddupUkrat213)}}</td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">14~25합산</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaCovrBomthAddupUkrat1425)}}</td>
                    </tr>
                    <tr>
                      <th rowspan="3">3. 순소득</th>
                      <th class="txt-left bdRN">직전1</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaNetIncmAmt01)}}</td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">직전2</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaNetIncmAmt02)}}</td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">직전3</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaNetIncmAmt03)}}</td>
                    </tr>
                    <tr>
                      <th rowspan="2">4.본인계약<br/>(정상)</th>
                      <th class="txt-left bdRN">보유건</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaSelfContRetCnt)}}</td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">보유월초</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaSelfContRetBomthPrm)}}</td>
                    </tr>
                    <tr>
                      <th rowspan="2">5.본인계약<br/>(실효계약)</th>
                      <th class="txt-left bdRN">보유건</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaTeyWthSelfCcontCnt)}}</td>
                    </tr>
                    <tr>
                      <th class="txt-left bdRN">보유월초</th>
                      <td class="txt-right">{{$bizUtil.numberWithCommas(rsltItem.zaTeyWthSelfCcontPrm)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- //table -->

              <div class="sub-text mt20">
                <div class="info-li">
                  <span class="fs18rem fwb mr6"  v-if="rsltItem.zaPlanType === '80'">본인계약건 확인</span>
                  <span class="fs18rem fwb mr6"  v-if="rsltItem.zaPlanType === '82'">분산모집건 확인</span>
                  <span class="fs18rem fwb mr6"  v-if="rsltItem.zaPlanType === '81'">단기반복해지건 확인</span>
                  <span class="fs18rem fwb mr6"  v-if="rsltItem.zaPlanType === '83'">JUST달성건 확인</span>
                  <span class="fs18rem fwb mr6"  v-if="rsltItem.zaPlanType === '84'">대체계약건 확인</span>
                </div>
                <div class="info-li">
                  <span class="mr6 fs16rem">
                    <span class="mr6 fs16rem"  v-if="rsltItem.zaPlanType === '80' && rsltItem.zaSelfContScCd !== '02'">해당 신계약은 컨설턴트 본인의 신계약을 진행중인 계약입니다. 해당건은 평가에 불이익이 있을 수 있으니 참고하여 결재 바랍니다.</span>
                    <span class="mr6 fs16rem"  v-if="rsltItem.zaPlanType === '80' && rsltItem.zaSelfContScCd === '02'">해당 신계약은 영업관리자 본인의 신계약을 진행중인 계약입니다. 해당건은 평가에 불이익이 있을 수 있으니 참고하여 결재 바랍니다.</span>
                    <span class="mr6 fs16rem"  v-if="rsltItem.zaPlanType === '82' && rsltItem.zaDisqScCd !== '02'">해당 신계약은 직전3개월&동일지점 내 동일고객에 대한 2명 이상의 모집FC 발생건으로, 해당건은 평가에 불이익이 있을 수 있으니 참고하여 결재 바랍니다.</span>
                    <span class="mr6 fs16rem"  v-if="rsltItem.zaPlanType === '82' && rsltItem.zaDisqScCd === '02'">해당 신계약은 CM/CA/팀장의 본가계약(본인, 가족)이면서, 모집FC가 지역단 내 타 FC인 경우로, 해당건은 평가에 불이익이 있을 수 있으니 참고하여 결재 바랍니다.</span>
                    <span class="mr6 fs16rem"  v-if="rsltItem.zaPlanType === '81'">해당 신계약은 2건이상 조기해지 및 실효 계약을 보유한 계약자와 진행중인 계약입니다. 해당건은 평가에 불이익이 있을 수 있으니 참고하여 결재 바랍니다.</span>
                    <span class="mr6 fs16rem"  v-if="rsltItem.zaPlanType === '83'">해당 신계약은 JUST달성 계약입니다. 해당건은 평가에 불이익이 있을 수 있으니 참고하여 결재 바랍니다.</span>
                    <span class="mr6 fs16rem"  v-if="rsltItem.zaPlanType === '84'">해당 신계약은 대체계약입니다. 해당건은 평가에 불이익이 있을 수 있으니 참고하여 결재 바랍니다.</span>
                  </span>
                </div>
              </div>
            </ur-box-container>

            <!-- 질문리스트 영역 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="question-area pb20">
              <div class="list-item">
                <div class="q-before indent15">
                  <b class="num">1.</b>
                  <span class="ml4 must" v-if="rsltItem.zaPlanType === '80' && rsltItem.zaSelfContScCd !== '02'">본계약은 FC 본인의 자발적인 필요에 의한 계약이 맞습니까?</span>
                  <span class="ml4 must" v-if="rsltItem.zaPlanType === '80' && rsltItem.zaSelfContScCd === '02'">본계약은 영업관리자 본인의 자발적인 필요에 의한 계약이 맞습니까?</span>
                  <span class="ml4 must" v-if="rsltItem.zaPlanType !== '80'">본계약은 계약자의 자발적인 필요에 의한 계약이 맞습니까?</span>
                </div>
                <div class="ns-radio-list mt10">
                  <mo-radio v-model="radioValue.radioList1[0]" value="1" class="w80px">예</mo-radio>
                  <mo-radio v-model="radioValue.radioList1[0]" value="0" class="w100px">아니오</mo-radio>
                </div>
              </div>
              <div class="list-item">
                <div class="q-before indent15">
                  <b class="num">2.</b>
                  <span class="ml4 must" v-if="rsltItem.zaPlanType === '80'">과도한 가입으로 인한 보험료 납입 부담 없는 계약이 맞습니까?</span>
                  <span class="ml4 must" v-if="rsltItem.zaPlanType !== '80'">본계약은 모집FC의 대면에 의한 충분한 상품설명이 있었습니까?</span>
                </div>
                <div class="ns-radio-list mt10">
                  <mo-radio v-model="radioValue.radioList1[1]" value="1" class="w80px">예</mo-radio>
                  <mo-radio v-model="radioValue.radioList1[1]" value="0" class="w100px">아니오</mo-radio>
                </div>
              </div>
              <div class="list-item" v-if="rsltItem.zaPlanType === '82' && rsltItem.zaDisqScCd === '02'">
                <div class="q-before indent15">
                  <b class="num">3.</b>
                  <span class="ml4 must">계약자의 모집FC 변경사유는 무엇입니까?</span>
                </div>
                <p class="fs13rem crTy-bk1">"Y" 체크 시 상세 사유 작성 필요<br/><span class="crTy-bk3">[예시] O O O 계약자가 기존에는 모친인 O O O FC에게 계약하였으나, 친구인 O O O FC와의 친분으로 추가계약을 진행 함</span></p>
                <div class="wrap-input mt10">
                  <mo-text-area v-model="invwCntnt8202" placeholder="입력" class="w100 lhauto"/>
                </div>
              </div>
              <div class="list-item">
                <div class="q-before indent15">
                  <b class="num" v-if="rsltItem.zaPlanType !== '82' || rsltItem.zaDisqScCd !== '02'">3.</b>
                  <b class="num" v-if="rsltItem.zaPlanType === '82' && rsltItem.zaDisqScCd === '02'">4.</b>
                  <span class="ml4 must" v-if="rsltItem.zaPlanType === '80'">본계약의 지속 유지를 위한 FC 본인 의지를 확인 했습니까?</span>
                  <span class="ml4 must" v-if="rsltItem.zaPlanType !== '80'">본계약의 지속 유지를 위한 계약자의 의지를 확인 했습니까?</span>
                </div>
                <div class="ns-radio-list mt10">
                  <mo-radio v-model="radioValue.radioList1[2]" value="1" class="w80px">예</mo-radio>
                  <mo-radio v-model="radioValue.radioList1[2]" value="0" class="w100px">아니오</mo-radio>
                </div>
              </div>
              <div class="list-item" v-if="rsltItem.zaPlanType === '82' || rsltItem.zaPlanType === '84'">
                <div class="q-before indent15">
                  <b class="num" v-if="rsltItem.zaPlanType !== '82' || rsltItem.zaDisqScCd !== '02'">4.</b>
                  <b class="num" v-if="rsltItem.zaPlanType === '82' && rsltItem.zaDisqScCd === '02'">5.</b>
                  <span class="ml4 must" v-if="rsltItem.zaPlanType === '82'">계약자의 기존 보험계약 중 직전1년 내 해지/실효건이 있습니까?</span>
                  <span class="ml4 must" v-if="rsltItem.zaPlanType === '84'">본계약은 계약자가 기존계약 해지 후 신계약임을 인지하고 있습니까?</span>
                </div>
                <div class="ns-radio-list mt10">
                  <mo-radio v-model="radioValue.radioList1[3]" value="1" class="w80px">예</mo-radio>
                  <mo-radio v-model="radioValue.radioList1[3]" value="0" class="w100px">아니오</mo-radio>
                </div>
                <p class="fs13rem crTy-bk1" v-if="rsltItem.zaPlanType === '82'">"Y" 체크 시 상세 사유 작성 필요<br/><span class="crTy-bk3">[예시] 직전 1년 내 해지이력이 있는 고객이나, 고객이 금번 계약은 지속 유지하고자 함을 확인 함</span></p>
                <p class="fs13rem crTy-bk1" v-if="rsltItem.zaPlanType === '84'">"Y" 체크 시 상세 사유 작성 필요<br/><span class="crTy-bk3">[예시] 기존 건강상품 가입 고객이나, 보장내용 변경을 원하여 신계약을 가입하고자 함</span></p>
              </div>
              <div class="list-item">
                <div class="q-before indent15">
                  <b class="num indent15" v-if="rsltItem.zaPlanType !== '82' && rsltItem.zaPlanType !== '84'">4.</b>
                  <b class="num indent15" v-if="rsltItem.zaPlanType === '82' && rsltItem.zaDisqScCd !== '02'">5.</b>
                  <b class="num indent15" v-if="rsltItem.zaPlanType === '82' && rsltItem.zaDisqScCd === '02'">6.</b>
                  <b class="num indent15" v-if="rsltItem.zaPlanType === '84'">5.</b>
                  <span class="ml4 must">면담사항 입력</span>
                </div>
                <div class="wrap-input mt10">
                  <mo-text-area v-model="invwCntnt" placeholder="입력" class="w100 lhauto"/>
                </div>
              </div>
            </ur-box-container>
            <!-- //질문리스트 영역 -->

            <!-- 1차 결재 -->
            <ur-box-container direction="column" class="con-area mt15">
              <div class="contain-title fs19rem mt20">1차 결재</div>
              <div class="w100 mt10">
                <span class="label-title must">승인여부</span>
                <div class="ns-radio-list mt10">
                  <mo-radio v-model="radioValue.radioList2[0]" value="1" class="w80px" :disabled="isFirstDisable">예</mo-radio>
                  <mo-radio v-model="radioValue.radioList2[0]" value="0" class="w100px" :disabled="isFirstDisable">아니오</mo-radio>
                </div>
              </div>
              <div class="fexTy-half24 flex w100 mt30">
                <ur-box-container alignV="start" componentid="" direction="column">
                  <span class="label-title mb0">승인자 사번</span>
                  <div class="row-text i-inp-pr30 req-item-autofull full">
                    <mo-text-field class="form-input-name txt-left" underline placeholder="" v-model="sancEno1" readonly="readonly" />
                  </div>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="ml20">
                  <span class="label-title mb0">승인자명</span>
                  <div class="row-text i-inp-pr30 req-item-autofull full">
                    <mo-text-field class="form-input-name txt-left" underline placeholder="" v-model="sancNm1" readonly="readonly" />
                  </div>
                </ur-box-container>
              </div>
              <div class="full mt30">
                <ur-box-container alignV="start" componentid="" direction="column">
                  <span class="label-title mb0">승인일시</span>
                  <div class="row-text i-inp-pr30 full">
                    <mo-text-field class="form-input-name txt-left" underline placeholder="" v-model="sancDtm1"  readonly="readonly" />
                  </div>
                </ur-box-container>
              </div>
            </ur-box-container>
            <!-- //1차 결재 -->
            <!-- 2차 결재 -->
            <ur-box-container direction="column" class="con-area mt15 mb50" v-if="secondSancObjYn==='Y'">
              <div class="contain-title fs19rem mt20">2차 결재</div>
              <div class="w100 mt10">
                <span class="label-title must">승인여부</span>
                <div class="ns-radio-list mt10">
                  <mo-radio v-model="radioValue.radioList2[1]" value="1" class="w80px" :disabled="isSecondDisable">예</mo-radio>
                  <mo-radio v-model="radioValue.radioList2[1]" value="0" class="w100px" :disabled="isSecondDisable">아니오</mo-radio>
                </div>
              </div>
              <div class="fexTy-half24 flex w100 mt30">
                <ur-box-container alignV="start" componentid="" direction="column">
                  <span class="label-title mb0">승인자 사번</span>
                  <div class="row-text i-inp-pr30 req-item-autofull full">
                    <mo-text-field class="form-input-name txt-left" underline placeholder="" v-model="sancEno2" readonly="readonly" />
                  </div>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="ml20">
                  <span class="label-title mb0">승인자명</span>
                  <div class="row-text i-inp-pr30 req-item-autofull full">
                    <mo-text-field class="form-input-name txt-left" underline placeholder="" v-model="sancNm2" readonly="readonly" />
                  </div>
                </ur-box-container>
              </div>
              <div class="full flex w100 mt30">
                <ur-box-container alignV="start" componentid="" direction="column">
                  <span class="label-title mb0">승인일시</span>
                  <div class="row-text i-inp-pr30 full">
                    <mo-text-field class="form-input-name txt-left" underline placeholder="" v-model="sancDtm2"  readonly="readonly" />
                  </div>
                </ur-box-container>
              </div>
            </ur-box-container>
            <!-- //2차 결재 -->

            <!-- button bar -->
            <template>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="" color="normal" shape="border" size="medium" @click="fn_Cancel" class="ns-btn-round white">취소</mo-button>
                  <mo-button componentid="" color="normal" shape="border" size="medium" @click="fn_Confirm" class="ns-btn-round blue" :disabled="isSaveDisable">저장</mo-button>
                </div>
              </ur-box-container>
            </template>
            <!-- //button bar -->
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>

import Msg from '@/systems/webkit/msg/msg'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import MSPPS621P from '@/ui/ps/MSPPS621P'

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPPS621P",
    screenId: "MSPPS621P",
    components: {},
    /***********************************************************************************
    * 화면 UI Property 정의
    * - 파리미터 받는 변수 props 정의
    ***********************************************************************************/
    props:{
      itemData: {type: Object}
    },//props    
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.inputData = this.itemData
    },
    mounted () {
     // 스크롤 애니메이션 instance 선언
      // this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      // document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      this.fn_ServiceData('S')

    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        inputData: {}, // 받아온 전체 데이터
        //rsltItem: {},
        radioValue: {
          radioList1: ['', '', '', ''],
          radioList2: ['', ''],
        },
        secondSancObjYn:'Y',
        isFirstDisable:true,
        isSecondDisable:true,
        isSaveDisable:false,
        invwCntnt:'',
        invwCntnt8202:'',
        sancEno1:'',
        sancNm1:'', 
        sancDtm1:'',
        sancYn2:'',
        sancEno2:'',
        sancNm2:'',
        sancDtm2:'',
        rsltItem:
          {
            zaClctCnsltNoNm:'',
            zaPlanType:'',
            zaDisqScCd:'',
            zaClctCnsltNo:'',
            clctDofOrgNo:'',
            clctFofOrgNo:'',
            zaDofOrgNm:'',
            zaFofOrgNm:'',
            zaLpmfc:'',
            zaDutyNm:'',
            zaQulfNm:'',
            zaEomRspnAmt:'',
            zaExcpAplcNcontCnt:'',
            zaCnvlPrfmAmt:'',
            zaContCnvlPrfmAmt:'',
            zaSumCnvlPrfmAmt:'',
            zaStndYmd:'',
            zaSelfNcontCnt:'',       
            zaSelfCnvlPrfmAmt:'',    
            zaSelgMpdbPrm:'',  
            zaSelfCovrBomthPrm:'',    
            zaCovrBomthAddupUkrat213:'',
            zaCovrBomthAddupUkrat1425:'',     
            zaNetIncmAmt01:'',    
            zaNetIncmAmt02:'',    
            zaNetIncmAmt03:'',    
            zaSelfContRetCnt:'',    
            zaSelfContRetBomthPrm:'',    
            zaTeyWthSelfCcontCnt:'',    
            zaTeyWthSelfCcontPrm:'',    
            invwCntnt:'',      
            invwCntnt8202:'',      
            sancYn1:'', 
            sancEno1:'',
            sancNm1:'', 
            sancDtm1:'',
            sancYn2:'',
            sancEno2:'',
            sancNm2:'',
            sancDtm2:'',
            zaSelfContScCd:'',
            zaFirstSancAuthYn:'',
            zaSecondSancAuthYn:''
          },
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명  : fn_ServiceData
      * 설명        : 서비스 호출 함수 정의
      ******************************************************************************/
      fn_ServiceData (serviceName) {

        let pParams = {}
        pParams.data = {}
        pParams.appId = 'sli.ipi'
        switch (serviceName) {
          case 'S': // 사전결재현황 조회
            pParams.fnctScCd = 'S'
            pParams.trnstId = 'txTSSPS96S2'
            pParams.serviceId = 'C339_F1PQL0509_S'
            pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0509VO'
            pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0509VO'
            pParams.data.zBefrSancTrtDto = {}
            pParams.data.zBefrSancTrtDto.zaPrcsCd = 'S'
            pParams.data.zBefrSancTrtDto.zaPlanType = this.inputData.zaOfrAddmInfoScCd
            pParams.data.zBefrSancTrtDto.zaUserId = this.inputData.zaUserId
            pParams.data.zBefrSancTrtDto.zaUntyMngNoScCd = '01'
            pParams.data.zBefrSancTrtDto.zaUntyMngNo = this.inputData.zaPlanId
            pParams.data.zBefrSancTrtDto.zaCnsltNo = this.inputData.zaClctCnsltNo
            break
          case 'P1':
            pParams.fnctScCd = 'U'
            pParams.trnstId = 'txTSSPS96U2'
            pParams.serviceId = 'C339_F1PQL0509_S'
            pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0509VO'
            pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0509VO'
            pParams.data.zBefrSancTrtDto = {}
            pParams.data.zBefrSancTrtDto.zaPrcsCd = 'U'
            pParams.data.zBefrSancTrtDto.zaPlanType = this.inputData.zaOfrAddmInfoScCd
            pParams.data.zBefrSancTrtDto.zaUserId = this.inputData.zaUserId
            pParams.data.zBefrSancTrtDto.zaUntyMngNoScCd = '01'
            pParams.data.zBefrSancTrtDto.zaUntyMngNo = this.inputData.zaPlanId
            pParams.data.zBefrSancTrtDto.zaCnsltNo = this.inputData.zaClctCnsltNo
            pParams.data.zBefrSancTrtDto.zaSelfContScCd = this.rsltItem.zaSelfContScCd
            
            // 본인계약, 단기반복해지
            if (this.inputData.zaOfrAddmInfoScCd === '80' || this.inputData.zaOfrAddmInfoScCd === '81') {
              if (this.radioValue.radioList1[0] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns23 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns23 = 'N'
              }
              if (this.radioValue.radioList1[1] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns24 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns24 = 'N'
              }
              if (this.radioValue.radioList1[2] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns25 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns25 = 'N'
              }
              if (this.invwCntnt !== '') {
                pParams.data.zBefrSancTrtDto.zaAns21 = this.invwCntnt
              }
            }
            // 분산모집
            if (this.inputData.zaOfrAddmInfoScCd === '82') {
              if (this.rsltItem.zaDisqScCd === '02') {
                if (this.radioValue.radioList1[0] === '1') {
                  pParams.data.zBefrSancTrtDto.zaAns11 = 'Y'
                } else {
                  pParams.data.zBefrSancTrtDto.zaAns11 = 'N'
                }
                if (this.radioValue.radioList1[1] === '1') {
                  pParams.data.zBefrSancTrtDto.zaAns12 = 'Y'
                } else {
                  pParams.data.zBefrSancTrtDto.zaAns12 = 'N'
                }
                if (this.invwCntnt8202 !== '') {
                  pParams.data.zBefrSancTrtDto.zaAns13 = this.invwCntnt8202
                }                        
                if (this.radioValue.radioList1[2] === '1') {
                  pParams.data.zBefrSancTrtDto.zaAns14 = 'Y'
                } else {
                  pParams.data.zBefrSancTrtDto.zaAns14 = 'N'
                }
                if (this.radioValue.radioList1[3] === '1') {
                  pParams.data.zBefrSancTrtDto.zaAns15 = 'Y'
                } else {
                  pParams.data.zBefrSancTrtDto.zaAns15 = 'N'
                }
                if (this.invwCntnt !== '') {
                  pParams.data.zBefrSancTrtDto.zaAns16 = this.invwCntnt
                }                        
              } else {
                if (this.radioValue.radioList1[0] === '1') {
                  pParams.data.zBefrSancTrtDto.zaAns01 = 'Y'
                } else {
                  pParams.data.zBefrSancTrtDto.zaAns01 = 'N'
                }
                if (this.radioValue.radioList1[1] === '1') {
                  pParams.data.zBefrSancTrtDto.zaAns02 = 'Y'
                } else {
                  pParams.data.zBefrSancTrtDto.zaAns02 = 'N'
                }
                if (this.radioValue.radioList1[2] === '1') {
                  pParams.data.zBefrSancTrtDto.zaAns03 = 'Y'
                } else {
                  pParams.data.zBefrSancTrtDto.zaAns03 = 'N'
                }
                if (this.radioValue.radioList1[3] === '1') {
                  pParams.data.zBefrSancTrtDto.zaAns04 = 'Y'
                } else {
                  pParams.data.zBefrSancTrtDto.zaAns04 = 'N'
                }
                if (this.invwCntnt !== '') {
                  pParams.data.zBefrSancTrtDto.zaAns05 = this.invwCntnt
                }                        
              }
            }
            // JUST달성
            if (this.inputData.zaOfrAddmInfoScCd === '83') {
              if (this.radioValue.radioList1[0] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns01 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns01 = 'N'
              }
              if (this.radioValue.radioList1[1] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns02 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns02 = 'N'
              }
              if (this.radioValue.radioList1[2] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns03 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns03 = 'N'
              }
              if (this.invwCntnt !== '') {
                pParams.data.zBefrSancTrtDto.zaAns04 = this.invwCntnt
              }                        
            }
            // 대체계약
            if (this.inputData.zaOfrAddmInfoScCd === '84') {
              if (this.radioValue.radioList1[0] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns22 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns22 = 'N'
              }
              if (this.radioValue.radioList1[1] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns23 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns23 = 'N'
              }
              if (this.radioValue.radioList1[2] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns24 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns24 = 'N'
              }
              if (this.radioValue.radioList1[3] === '1') {
                pParams.data.zBefrSancTrtDto.zaAns25 = 'Y'
              } else {
                pParams.data.zBefrSancTrtDto.zaAns25 = 'N'
              }
              if (this.invwCntnt !== '') {
                pParams.data.zBefrSancTrtDto.zaAns21 = this.invwCntnt
              }
            }

            // 1차결재 (활성화 상태에서 승인여부 선택된 경우에 DB반영)
            if (this.radioValue.radioList2[0] === '1') {
              pParams.data.zBefrSancTrtDto.zaAprvYn = 'Y'
              pParams.data.zBefrSancTrtDto.zaSancrEno = this.sancEno1
              pParams.data.zBefrSancTrtDto.zaSancrNm = this.sancNm1
            } else if (this.radioValue.radioList2[0] === '0') {
              pParams.data.zBefrSancTrtDto.zaAprvYn = 'N'
              pParams.data.zBefrSancTrtDto.zaSancrEno = this.sancEno1
              pParams.data.zBefrSancTrtDto.zaSancrNm = this.sancNm1
            }
            // 2차결재 (활성화 상태에서 승인여부 선택된 경우에 DB반영)
            if (this.radioValue.radioList2[1] === '1') {
              pParams.data.zBefrSancTrtDto.zaAprvYn2 = 'Y'
              pParams.data.zBefrSancTrtDto.zaSancrEno2 = this.sancEno2
              pParams.data.zBefrSancTrtDto.zaSancrNm2 = this.sancNm2
            } else if (this.radioValue.radioList2[1] === '0') {
              pParams.data.zBefrSancTrtDto.zaAprvYn2 = 'N'
              pParams.data.zBefrSancTrtDto.zaSancrEno2 = this.sancEno2
              pParams.data.zBefrSancTrtDto.zaSancrNm2 = this.sancNm2
            }

            break
          default:
            break
        }
        pParams.srnId = this.$options.screenId // 대표 화면 명
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
      },
      /************************************************************************************************
      * Function명  : fn_ServiceResultHandler
      * 설명        : 서비스 호출 결과 함수 정의
      ************************************************************************************************/
      fn_ServiceResultHandler (event, serviceName) {
        let lv_data = event.data

        switch (serviceName) {

          case 'S': // 사전결재현황 조회
            let t_rslt
            if (lv_data.zbefrSancTrtDto) {
              t_rslt = lv_data.zbefrSancTrtDto
            }
            if (t_rslt) {
              //this.rsltItem['zaPblYmdLabel'] = this.inputData.zaPblYmdLabel
              this.rsltItem.zaPblYmdLabel = this.inputData.zaPblYmdLabel
              this.rsltItem.zaClctCnsltNoNm = this.inputData.zaClctCnsltNoNm
              this.rsltItem.zaClctCnsltNo = this.inputData.zaClctCnsltNo
              this.rsltItem.zaDofOrgNm = this.inputData.zaDofOrgNm
              this.rsltItem.zaFofOrgNm = this.inputData.zaFofOrgNm
              this.rsltItem.zaPlanType = this.inputData.zaOfrAddmInfoScCd
              
              this.rsltItem.zaDisqScCd = t_rslt.zaDisqScCd
              this.rsltItem.zaSelfContScCd = t_rslt.zaSelfContScCd

              if (t_rslt.ztpqlsts25Dto) {
                this.rsltItem.zaLpmfc = t_rslt.ztpqlsts25Dto[0].zaLpmfc
                this.rsltItem.zaDutyNm = t_rslt.ztpqlsts25Dto[0].zaDutyNm
                this.rsltItem.zaQulfNm = t_rslt.ztpqlsts25Dto[0].zaQulfNm
                this.rsltItem.zaEomRspnAmt = t_rslt.ztpqlsts25Dto[0].zaEomRspnAmt
                this.rsltItem.zaExcpAplcNcontCnt = t_rslt.ztpqlsts25Dto[0].zaExcpAplcNcontCnt
                this.rsltItem.zaCnvlPrfmAmt = t_rslt.ztpqlsts25Dto[0].zaCnvlPrfmAmt
                this.rsltItem.zaContCnvlPrfmAmt = t_rslt.ztpqlsts25Dto[0].zaContCnvlPrfmAmt
                this.rsltItem.zaSumCnvlPrfmAmt = t_rslt.ztpqlsts25Dto[0].zaSumCnvlPrfmAmt
                this.rsltItem.zaSelfNcontCnt = t_rslt.ztpqlsts25Dto[0].zaSelfNcontCnt
                this.rsltItem.zaSelfCnvlPrfmAmt = t_rslt.ztpqlsts25Dto[0].zaSelfCnvlPrfmAmt
                this.rsltItem.zaSelgMpdbPrm = t_rslt.ztpqlsts25Dto[0].zaSelgMpdbPrm
                this.rsltItem.zaSelfCovrBomthPrm = t_rslt.ztpqlsts25Dto[0].zaSelfCovrBomthPrm
                this.rsltItem.zaCovrBomthAddupUkrat213 = t_rslt.ztpqlsts25Dto[0].zaCovrBomthAddupUkrat213
                this.rsltItem.zaCovrBomthAddupUkrat1425 = t_rslt.ztpqlsts25Dto[0].zaCovrBomthAddupUkrat1425
                this.rsltItem.zaNetIncmAmt01 = t_rslt.ztpqlsts25Dto[0].zaNetIncmAmt01
                this.rsltItem.zaNetIncmAmt02 = t_rslt.ztpqlsts25Dto[0].zaNetIncmAmt02
                this.rsltItem.zaNetIncmAmt03 = t_rslt.ztpqlsts25Dto[0].zaNetIncmAmt03
                this.rsltItem.zaSelfContRetCnt = t_rslt.ztpqlsts25Dto[0].zaSelfContRetCnt
                this.rsltItem.zaSelfContRetBomthPrm = t_rslt.ztpqlsts25Dto[0].zaSelfContRetBomthPrm
                this.rsltItem.zaTeyWthSelfCcontCnt = t_rslt.ztpqlsts25Dto[0].zaTeyWthSelfCcontCnt
                this.rsltItem.zaTeyWthSelfCcontPrm = t_rslt.ztpqlsts25Dto[0].zaTeyWthSelfCcontPrm
                this.rsltItem.zaStndYmd = t_rslt.ztpqlsts25Dto[0].zaStndYmd
              }

              // 본인계약, 단기반복해지
              if (this.inputData.zaOfrAddmInfoScCd === '80' || this.inputData.zaOfrAddmInfoScCd === '81') {
                if (t_rslt.zaAns23 === 'Y') {
                  this.radioValue.radioList1[0] = '1'
                } else if (t_rslt.zaAns23 === 'N') {
                  this.radioValue.radioList1[0] = '0'
                } else {
                  this.radioValue.radioList1[0] = ''
                }
                if (t_rslt.zaAns24 === 'Y') {
                  this.radioValue.radioList1[1] = '1'
                } else if (t_rslt.zaAns24 === 'N') {
                  this.radioValue.radioList1[1] = '0'
                } else {
                  this.radioValue.radioList1[1] = ''
                }
                if (t_rslt.zaAns25 === 'Y') {
                  this.radioValue.radioList1[2] = '1'
                } else if (t_rslt.zaAns25 === 'N') {
                  this.radioValue.radioList1[2] = '0'
                } else {
                  this.radioValue.radioList1[2] = ''
                }
                if (t_rslt.zaAns21 !== '') {
                  this.invwCntnt = t_rslt.zaAns21
                }
              }

              // 분산모집
              if (this.inputData.zaOfrAddmInfoScCd === '82') {
                if (t_rslt.zaDisqScCd === '02') {
                  if (t_rslt.zaAns11 === 'Y') {
                    this.radioValue.radioList1[0] = '1'
                  } else if (t_rslt.zaAns11 === 'N') {
                    this.radioValue.radioList1[0] = '0'
                  } else {
                    this.radioValue.radioList1[0] = ''
                  }
                  if (t_rslt.zaAns12 === 'Y') {
                    this.radioValue.radioList1[1] = '1'
                  } else if (t_rslt.zaAns12 === 'N') {
                    this.radioValue.radioList1[1] = '0'
                  } else {
                    this.radioValue.radioList1[1] = ''
                  }
                  if (t_rslt.zaAns13 !== '') {
                    this.invwCntnt8202 = t_rslt.zaAns13
                  }
                  if (t_rslt.zaAns14 === 'Y') {
                    this.radioValue.radioList1[2] = '1'
                  } else if (t_rslt.zaAns14 === 'N') {
                    this.radioValue.radioList1[2] = '0'
                  } else {
                    this.radioValue.radioList1[2] = ''
                  }
                  if (t_rslt.zaAns15 === 'Y') {
                    this.radioValue.radioList1[3] = '1'
                  } else if (t_rslt.zaAns15 === 'N') {
                    this.radioValue.radioList1[3] = '0'
                  } else {
                    this.radioValue.radioList1[3] = ''
                  }
                  if (t_rslt.zaAns16 !== '') {
                    this.invwCntnt = t_rslt.zaAns16
                  }
                } else {
                  if (t_rslt.zaAns01 === 'Y') {
                    this.radioValue.radioList1[0] = '1'
                  } else if (t_rslt.zaAns01 === 'N') {
                    this.radioValue.radioList1[0] = '0'
                  } else {
                    this.radioValue.radioList1[0] = ''
                  }
                  if (t_rslt.zaAns02 === 'Y') {
                    this.radioValue.radioList1[1] = '1'
                  } else if (t_rslt.zaAns02 === 'N') {
                    this.radioValue.radioList1[1] = '0'
                  } else {
                    this.radioValue.radioList1[1] = ''
                  }
                  if (t_rslt.zaAns03 === 'Y') {
                    this.radioValue.radioList1[2] = '1'
                  } else if (t_rslt.zaAns03 === 'N') {
                    this.radioValue.radioList1[2] = '0'
                  } else {
                    this.radioValue.radioList1[2] = ''
                  }
                  if (t_rslt.zaAns04 === 'Y') {
                    this.radioValue.radioList1[3] = '1'
                  } else if (t_rslt.zaAns04 === 'N') {
                    this.radioValue.radioList1[3] = '0'
                  } else {
                    this.radioValue.radioList1[3] = ''
                  }
                  if (t_rslt.zaAns05 !== '') {
                    this.invwCntnt = t_rslt.zaAns05
                  }
                }
              }

              // JUST30달성
              if (this.inputData.zaOfrAddmInfoScCd === '83') {
                if (t_rslt.zaAns01 === 'Y') {
                  this.radioValue.radioList1[0] = '1'
                } else if (t_rslt.zaAns01 === 'N') {
                  this.radioValue.radioList1[0] = '0'
                } else {
                  this.radioValue.radioList1[0] = ''
                }
                if (t_rslt.zaAns02 === 'Y') {
                  this.radioValue.radioList1[1] = '1'
                } else if (t_rslt.zaAns02 === 'N') {
                  this.radioValue.radioList1[1] = '0'
                } else {
                  this.radioValue.radioList1[1] = ''
                }
                if (t_rslt.zaAns03 === 'Y') {
                  this.radioValue.radioList1[2] = '1'
                } else if (t_rslt.zaAns03 === 'N') {
                  this.radioValue.radioList1[2] = '0'
                } else {
                  this.radioValue.radioList1[2] = ''
                }
                if (t_rslt.zaAns04 !== '') {
                  this.invwCntnt = t_rslt.zaAns04
                }                  
              }

              // 대체계약
              if (this.inputData.zaOfrAddmInfoScCd === '84') {
                if (t_rslt.zaAns22 === 'Y') {
                  this.radioValue.radioList1[0] = '1'
                } else if (t_rslt.zaAns22 === 'N') {
                  this.radioValue.radioList1[0] = '0'
                } else {
                  this.radioValue.radioList1[0] = ''
                }
                if (t_rslt.zaAns23 === 'Y') {
                  this.radioValue.radioList1[1] = '1'
                } else if (t_rslt.zaAns23 === 'N') {
                  this.radioValue.radioList1[1] = '0'
                } else {
                  this.radioValue.radioList1[1] = ''
                }
                if (t_rslt.zaAns24 === 'Y') {
                  this.radioValue.radioList1[2] = '1'
                } else if (t_rslt.zaAns24 === 'N') {
                  this.radioValue.radioList1[2] = '0'
                } else {
                  this.radioValue.radioList1[2] = ''
                }
                if (t_rslt.zaAns25 === 'Y') {
                  this.radioValue.radioList1[3] = '1'
                } else if (t_rslt.zaAns25 === 'N') {
                  this.radioValue.radioList1[3] = '0'
                } else {
                  this.radioValue.radioList1[3] = ''
                }
                if (t_rslt.zaAns21 !== '') {
                  this.invwCntnt = t_rslt.zaAns21
                }
              }
              // 결재1-승인일시
              let tmpSancDtm1 = ''
              if (t_rslt.zaAprvYn.trim() !== '') {
                tmpSancDtm1 = t_rslt.zaLstMdfcYmd.substring(0, 4)
                tmpSancDtm1 += '.'
                tmpSancDtm1 += t_rslt.zaLstMdfcYmd.substring(4, 6)
                tmpSancDtm1 += '.'
                tmpSancDtm1 += t_rslt.zaLstMdfcYmd.substring(6, 8)
                tmpSancDtm1 += ' '
                tmpSancDtm1 += t_rslt.zaLstMdfcTod.substring(0, 2)
                tmpSancDtm1 += ':'
                tmpSancDtm1 += t_rslt.zaLstMdfcTod.substring(2, 4)
                tmpSancDtm1 += ':'
                tmpSancDtm1 += t_rslt.zaLstMdfcTod.substring(4, 6)
              }
              this.sancEno1 = t_rslt.zaSancrEno
              this.sancNm1 = t_rslt.zaSancrNm
              this.sancDtm1 = tmpSancDtm1

              // 결재2-승인일시
              let tmpSancDtm2 = ''
              if (t_rslt.zaAprvYn2.trim() !== '') {
                tmpSancDtm2 = t_rslt.zaLstMdfcYmd2.substring(0, 4)
                tmpSancDtm2 += '.'
                tmpSancDtm2 += t_rslt.zaLstMdfcYmd2.substring(4, 6)
                tmpSancDtm2 += '.'
                tmpSancDtm2 += t_rslt.zaLstMdfcYmd2.substring(6, 8)
                tmpSancDtm2 += ' '
                tmpSancDtm2 += t_rslt.zaLstMdfcTod2.substring(0, 2)
                tmpSancDtm2 += ':'
                tmpSancDtm2 += t_rslt.zaLstMdfcTod2.substring(2, 4)
                tmpSancDtm2 += ':'
                tmpSancDtm2 += t_rslt.zaLstMdfcTod2.substring(4, 6)
              }
              this.sancEno2 = t_rslt.zaSancrEno2
              this.sancNm2 = t_rslt.zaSancrNm2
              this.sancDtm2 = tmpSancDtm2

              if (t_rslt.zaAprvYn.trim() === 'Y') {
                this.radioValue.radioList2[0] = '1'
              } else if (t_rslt.zaAprvYn.trim() === 'N') {
                this.radioValue.radioList2[0] = '0'
              } else {
                this.radioValue.radioList2[0] = ''        
              }
              if (t_rslt.zaAprvYn2 === 'Y') {
                this.radioValue.radioList2[1] = '1'
              } else if (t_rslt.zaAprvYn2 === 'N') {
                this.radioValue.radioList2[1] = '0'
              } else {
                this.radioValue.radioList2[1] = ''        
              }            

              //1. 아무 결재도 안 된 건
              //  1) 1차, 2차결재 권한 無 ==> 승인여부 비활성화, 사번,성명 공백
              //  2) 1차결재 권한 有 && 2차결재 권한 無 ==> (1차결재) 승인여부 활성화, 사번,성명 처리자셋팅, (2차결재) 승인여부 비활성화, 사번,성명 공백
              //  3) 1차결재 권한 無 && 2차결재 권한 有 ==> (1차결재, 2차결재) 승인여부 비활성화, 사번,성명 공백 (1차결재가 안 된 상태이기 때문에 2차결재 처리 불가)
              //  4) 1차, 2차결재 권한 有 ==> (1차결재, 2차결재) 승인여부 활성화, 사번,성명 처리자셋팅
              //2. 1차결재만 된 건
              //  1) 1차, 2차결재 권한 無 ==> (1차결재) 승인여부 비활성화, 사번,성명 기존 처리자 셋팅, (2차결재) 승인여부 비활성화, 사번,성명 공백
              //  2) 1차결재 권한 有 && 2차결재 권한 無 ==> (1차결재) 승인여부 비활성화, 사번,성명 기존 처리자 셋팅, (2차결재) 승인여부 비활성화, 사번,성명 공백
              //  3) 1차결재 권한 無 && 2차결재 권한 有 ==> (1차결재) 승인여부 비활성화, 사번,성명 기존 처리자 셋팅, (2차결재) 승인여부 활성화, 사번,성명 처리자셋팅
              //  4) 1차, 2차결재 권한 有 ==> (1차결재) 승인여부 비활성화, 사번,성명 기존 처리자 셋팅, (2차결재) 승인여부 활성화, 사번,성명 처리자셋팅
              if (t_rslt.zaAprvYn.trim() === '') {
                if (this.inputData.zaOfrAddmInfoScCd === '80') {
                  this.isFirstDisable = false
                  this.isSecondDisable = false
                  this.sancEno1 = PSServiceManager.getUserInfo('userId')
                  this.sancNm1 = PSServiceManager.getUserInfo('userNm')
                  this.sancEno2 = PSServiceManager.getUserInfo('userId')
                  this.sancNm2 = PSServiceManager.getUserInfo('userNm')                  
                } else {  
                  if (t_rslt.zaFirstSancAuthYn === 'Y' && t_rslt.zaSecondSancAuthYn !== 'Y') {
                    this.isFirstDisable = false
                    this.sancEno1 = PSServiceManager.getUserInfo('userId')
                    this.sancNm1 = PSServiceManager.getUserInfo('userNm')
                  }
                  if (t_rslt.zaFirstSancAuthYn === 'Y' && t_rslt.zaSecondSancAuthYn === 'Y') {
                    this.isFirstDisable = false
                    this.isSecondDisable = false
                    this.sancEno1 = PSServiceManager.getUserInfo('userId')
                    this.sancNm1 = PSServiceManager.getUserInfo('userNm')
                    this.sancEno2 = PSServiceManager.getUserInfo('userId')
                    this.sancNm2 = PSServiceManager.getUserInfo('userNm')
                  }
                }
              } else if (t_rslt.zaAprvYn2.trim() === '') {
                if (this.inputData.zaOfrAddmInfoScCd === '80') {
                  this.isSecondDisable = false
                  this.sancEno2 = PSServiceManager.getUserInfo('userId')
                  this.sancNm2 = PSServiceManager.getUserInfo('userNm')
                } else {
                  if (t_rslt.zaSecondSancAuthYn === 'Y') {
                    this.isSecondDisable = false
                    this.sancEno2 = PSServiceManager.getUserInfo('userId')
                    this.sancNm2 = PSServiceManager.getUserInfo('userNm')
                  }
                }
              }

              // 1,2차결재 본인결재내역은 수정가능
              if (this.sancEno1 === PSServiceManager.getUserInfo('userId')) {
                this.isFirstDisable = false
                this.sancEno1 = PSServiceManager.getUserInfo('userId')
                this.sancNm1 = PSServiceManager.getUserInfo('userNm')                    
              }
              if (this.sancEno2 === PSServiceManager.getUserInfo('userId')) {
                this.isSecondDisable = false
                this.sancEno2 = PSServiceManager.getUserInfo('userId')
                this.sancNm2 = PSServiceManager.getUserInfo('userNm')                
              }

            }

            if (this.inputData.zaOfrAddmInfoScCd === '80' || this.inputData.zaOfrAddmInfoScCd === '84') {
              this.secondSancObjYn = 'N'
            }

            //1차,2차 승인여부 둘 다 diabled 인 경우, 저장버튼 비활성화 처리
            if (this.isFirstDisable && this.isSecondDisable) {
              this.isSaveDisable = true
            }            
            break

          case 'P1':
            if (lv_data.zbefrSancTrtDto.nrmTrtYn === 'Y') {
              this.$emit('confirmPopup')              
            } else {
              this.getStore('confirm').dispatch('ADD', lv_data.zbefrSancTrtDto.untyMsgCntnt)
              //this.$emit('errorPopup')
            }
            break
          default:
            break
        }
      },
      /************************************************************************************************
      * Function명  : fn_ServiceFaultHandler
      * 설명        : 에러 이벤트
      ************************************************************************************************/
      fn_ServiceFaultHandler (event, serviceName) {
        switch (serviceName) {
          case 'S':
            if (event && event.msgCd !== 'EFWS034') {
              this.getStore('confirm').dispatch('ADD', event.msgDesc)
            } else {
              // 다른 시스템 호출 간 오류가 발생하였습니다.
              this.getStore('confirm').dispatch('ADD', '데이터 없는 경우이니\n확인후 다시 시도하세요')
            } // end if          
            break
          case 'P1':
            let msg = event.msgDesc || event.msgComm
            this.getStore('confirm').dispatch('ADD', msg)
            //this.$emit('errorPopup')
            break  
          default:
            break
        } // end switch

      },
      /******************************************************************************
      * Function명 : fn_Confirm
      * 설명       : 확인버튼 선택 시 호출
      ******************************************************************************/      
      fn_Confirm () {

        let chkSave = '00'
        if (this.inputData.zaOfrAddmInfoScCd === '80' || this.inputData.zaOfrAddmInfoScCd === '81' || this.inputData.zaOfrAddmInfoScCd === '83') {
          if (this.radioValue.radioList1[0] === '' || this.radioValue.radioList1[1] === '' || this.radioValue.radioList1[2] === '') {
            chkSave = '01'
          } else if (this.radioValue.radioList1[0] === '0' || this.radioValue.radioList1[1] === '0' || this.radioValue.radioList1[2] === '0') {
            chkSave = '02'
          }
        } else if (this.inputData.zaOfrAddmInfoScCd === '82') {
          if (this.radioValue.radioList1[0] === '' || this.radioValue.radioList1[1] === '' || this.radioValue.radioList1[2] === '' || this.radioValue.radioList1[3] === '') {
            chkSave = '01'
          } else if (this.radioValue.radioList1[0] === '0' || this.radioValue.radioList1[1] === '0' || this.radioValue.radioList1[2] === '0') {
            chkSave = '02'
          }
        } else if (this.inputData.zaOfrAddmInfoScCd === '84') {
          if (this.radioValue.radioList1[0] === '' || this.radioValue.radioList1[1] === '' || this.radioValue.radioList1[2] === '' || this.radioValue.radioList1[3] === '') {
            chkSave = '01'
          } else if (this.radioValue.radioList1[0] === '0' || this.radioValue.radioList1[1] === '0' || this.radioValue.radioList1[2] === '0' || this.radioValue.radioList1[3] === '0') {
            chkSave = '02'
          }
        }

        if (chkSave === '00') {
          if (this.invwCntnt === '') {
            chkSave = '03'
          }
          if (this.inputData.zaOfrAddmInfoScCd === '82' && this.rsltItem.zaDisqScCd === '02') {
            if (this.invwCntnt8202 === '') {
              chkSave = '03'
            }
          }
        }

        if (chkSave === '00') {
          if (this.radioValue.radioList2[0] === '') {
            if (this.sancEno1 !== '' || this.sancNm1 !== '') {
              chkSave = '04'
            }
          }
          if (this.isFirstDisable && this.radioValue.radioList2[1] === '') {
            if (this.sancEno2 !== '' || this.sancNm2 !== '') {
              chkSave = '04'
            }
          }
        }

        if (chkSave === '00') {
          if (this.radioValue.radioList2[0] === '' || this.radioValue.radioList2[0] === '0') {
            if (this.radioValue.radioList2[1] !== '') {
              chkSave = '05'
            }
          }
        }        

        if (chkSave === '01') {
          this.getStore('confirm').dispatch('ADD', '모든 질문에 "예/아니오" 를 선택해야합니다.')
        } else if (chkSave === '02') {
          this.getStore('confirm').dispatch('ADD', '"아니오" 체크시 저장 불가합니다.')
        } else if (chkSave === '03') {
          this.getStore('confirm').dispatch('ADD', '면담사항 입력 바랍니다.')
        } else if (chkSave === '04') {
          this.getStore('confirm').dispatch('ADD', '승인여부 선택 바랍니다.')
        } else if (chkSave === '05') {
          this.getStore('confirm').dispatch('ADD', '1차 승인 후 2차 승인 가능합니다.')
        } else {
          this.fn_ServiceData('P1')
        }
        //this.$emit('confirmPopup')
      },
      /******************************************************************************
      * Function명 : fn_Cancel
      * 설명       : 취소버튼 선택 시 호출
      ******************************************************************************/
      fn_Cancel () {
        this.$emit('cancelPopup')
      },
      /******************************************************************************
      * Function명 : fn_ClickBtnPrevious
      * 설명       : 이전 버튼 이벤트 - 팝업 화면 클로즈
      ******************************************************************************/
      fn_ClickBtnPrevious () {
        this.$emit('onPopupClose')
      },
    }
  };
</script>
<style scoped>
</style>